@font-face {
  font-family: 'Open Sans';
  src: local('Lato'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DroidSerif';
  src: local('Lato'), url(./assets/fonts/OpenSans-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DroidSerif';
  src: local('Lato'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
